import { createContext, useState } from "react";
import { FoodDto } from "../libs/foods";

export interface CartFoodContextType {
  price: number;
  increaseCart: any;
  decreaseCart: any;
  cartUpdated: boolean;
  cart: FoodDto[];
  addCart: (list: any) => void;
  restoreDefault: () => void;
  restoreCart: () => void;
  calculateTotal: (list: any) => void;
  addNote: (id: string | number, note: string) => void;
}

export const CartFoodContext = createContext<CartFoodContextType>({} as never);

const CartFoodContextProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [price, setPrice] = useState(0);
  const [cartUpdated, setCartUpdated] = useState(true);

  const restoreDefault = () => {
    setCart([]);
    setPrice(0);
    localStorage.setItem("cart", JSON.stringify([]));
    localStorage.setItem("price", JSON.stringify(0));
  };

  const restoreCart = () => {
    const cartData = localStorage.getItem("cart");
    const priceData = JSON.parse(localStorage.getItem("price"));
    if (cartData) {
      setCart(JSON.parse(cartData));
      setPrice(priceData);
    }
  };

  const calculateTotal = (list) => {
    if (list.length > 0) {
      const sum = (item) => item.reduce((x, y) => x + y);
      let total = sum(list.map((product) => Number(product.totalPrice)));
      setPrice(total);

      localStorage.setItem("price", total);
      setCartUpdated(!cartUpdated);
    } else {
      setPrice(0);
      localStorage.setItem("price", JSON.stringify(0));
      setCartUpdated(!cartUpdated);
    }
  };

  const addCart = (item: any) => {
    const cartData = [...cart, { ...item, total: 1, totalPrice: item.price }];
    setCart(cartData);
    calculateTotal(cartData);
    localStorage.setItem("cart", JSON.stringify(cartData));
  };

  const increaseCart = (item: any) => {
    const objIndex = cart.findIndex((select) => {
      return select.id === item.id;
    });
    let cartData = cart;
    cartData[objIndex].total += 1;
    cartData[objIndex].totalPrice =
      cartData[objIndex].total * cartData[objIndex].price;
    setCart(cartData);
    calculateTotal(cartData);
    localStorage.setItem("cart", JSON.stringify(cartData));
  };

  const decreaseCart = (item: any) => {
    const objIndex = cart.findIndex((select) => {
      return select.id === item.id;
    });
    let cartData = cart;
    if (cartData[objIndex].total > 1) {
      cartData[objIndex].total -= 1;
      cartData[objIndex].totalPrice =
        cartData[objIndex].total * cartData[objIndex].price;
      setCart(cartData);
      calculateTotal(cartData);
      localStorage.setItem("cart", JSON.stringify(cartData));
    } else {
      const newCart = cart.filter((obj) => {
        return obj.id !== item.id;
      });
      setCart(newCart);
      calculateTotal(newCart);
      localStorage.setItem("cart", JSON.stringify(newCart));
    }
  };

  const addNote = (id: string | number, note) => {
    let cartData = [];
    cart.map((item) => {
      if (item.id === id) {
        item.note = note;
      }
      cartData.push(item);
    });
    setCart(cartData);
    localStorage.setItem("cart", JSON.stringify(cartData));
  };

  return (
    <CartFoodContext.Provider
      value={{
        cart,
        price,
        restoreDefault,
        restoreCart,
        calculateTotal,
        addCart,
        increaseCart,
        decreaseCart,
        addNote,
        cartUpdated,
      }}
    >
      {children}
    </CartFoodContext.Provider>
  );
};

export default CartFoodContextProvider;
