import { Button, Container, makeStyles, Typography } from "@material-ui/core";
import { TFunction } from "next-i18next";
import React, { Fragment, useContext } from "react";
import { Router, withTranslation } from "../../i18n";
import Error400 from "../../public/img/Error400";
import Error404 from "../../public/img/Error404";
import Error500 from "../../public/img/Error500";
import Error503 from "../../public/img/Error503";
import { axiosInstance } from "../config/axios";
import { AppContext } from "../context/AppContext";

const useStyles = makeStyles({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    height: "100%",
    minHeight: "100vh",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  wrapper: {
    height: "100%",
    padding: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  errorMessage: {
    color: "#A0A4A8",
    marginTop: 16,
    fontSize: 14,
  },
  errorTitle: {
    marginTop: 32,
    fontSize: 16,
    fontWeight: 700,
    textAlign: "center",
  },
  buttonWrapper: {
    position: "fixed",
    bottom: 16,
    maxWidth: 444,
    width: "100%",
    padding: "0px 16px",
    display: "flex",
  },
  button: {
    fontWeight: "bold",
    borderRadius: 5,
    width: "50%",
    boxShadow: "0px 10px 15px rgba(51, 131, 225, 0.2)",
  },
});

interface Props {
  error?: number;
  errorMessage?: string;
  errorTitle?: string;
  children?: React.ReactNode;
  readonly t: TFunction;
}

function ErrorPage(props: Props) {
  const { error, errorMessage, errorTitle, children, t } = props;
  const classes = useStyles();
  const { setErrorResponse } = useContext(AppContext);

  axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");

    config.headers = Object.assign(
      {
        Authorization: `Bearer ${token}`,
      },
      config.headers
    );
    return config;
  });
  axiosInstance.interceptors.response.use(
    function (response: any) {
      return response;
    },
    function (error: any) {
      if (error.response.status === 503) {
        const errorObject = {
          status_code: error.response.status,
          error_code: "",
          errors: [],
          message: error.response.data.message,
          title: error.response.data.title,
        };
        setErrorResponse(errorObject);
        return;
      }
      setErrorResponse(error?.response?.data?.meta);
      return Promise.reject(error);
    }
  );

  const content = () => {
    switch (error) {
      case 400:
        return (
          <Fragment>
            <Error400 />
            <Typography className={classes.errorMessage}>
              {errorMessage}
            </Typography>
          </Fragment>
        );

      case 404:
        return (
          <Fragment>
            <Error404 />
            <Typography className={classes.errorMessage}>
              {errorMessage || "Not Found 404"}
            </Typography>
          </Fragment>
        );
      case 500:
        return (
          <Fragment>
            <Error500 />
            <Typography className={classes.errorMessage}>
              {t("internal-server-error-500")}
            </Typography>
          </Fragment>
        );
      case 503:
        return (
          <Fragment>
            <Error503 />
            <Typography className={classes.errorTitle}>{errorTitle}</Typography>
            <Typography className={classes.errorMessage}>
              {errorMessage}
            </Typography>
          </Fragment>
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      {error ? (
        <Container maxWidth="xs" className={classes.root}>
          <div className={classes.wrapper}>
            {content()}
            <div
              className={classes.buttonWrapper}
              style={{ columnGap: error === 404 ? 16 : 0 }}
            >
              {error === 404 && (
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={() => Router.push("/")}
                >
                  {t("back-to-home")}
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                style={{
                  width: error === 404 ? "50%" : "100%",
                }}
                className={classes.button}
                onClick={() => {
                  window.open(
                    `https://wa.me/${
                      process.env.WHATSAPP_DEFAULT || "6288802870057"
                    }`,
                    "_blank"
                  );
                }}
              >
                {t("contact-us")}
              </Button>
            </div>
          </div>
        </Container>
      ) : (
        children
      )}
    </Fragment>
  );
}

export default ErrorPage;
